<template>
  <div>
    <b-card no-body class="mb-0">

       <b-tabs ref="tabRef" content-class="pt-1 " fill>
        <b-tab :title="$t('Facility Users')">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-md-0
                "
              >
                <!-- <label>Show</label> -->
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("Show") }}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search...')"
                  />
                  <b-button
                    variant="primary"
                    :to="{ name: 'facility_user_create' }"
                  >
                    <span class="text-nowrap">{{ $t("Create Facility User") }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            hover
            ref="refUserListTable"
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPageConfirm"
            :items="filtered_facility_confirm_studio_data"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('No Matching Creates Found')"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(actions)="data">
              <template v-if="isMobile === true">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                      :to="{
                        name: 'facility_user_edit',
                        params: { personID: data.item.person_id, companyBranchPersonRoleId: data.item.company_branch_person_role_id},
                      }"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="success(data.item.trainer_id, data.item.is_active)"
                  >
                    <feather-icon icon="ActivityIcon" />
                    <span class="align-middle ml-50">
                      {{
                        data.item.is_active === "PASSIVE" ||
                        data.item.is_active === "PASİF"
                          ? $t("Activate")
                          : $t("Deactivate")
                      }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <template v-if="isMobile === false">
                <b-button
                  size="sm"
                  class="btn-size mr-1"
                  variant="primary"
                    :to="{
                      name: 'facility_user_edit',
                      params: { personID: data.item.person_id, companyBranchPersonRoleId: data.item.company_branch_person_role_id},
                    }"
                  >{{ $t("Edit") }}</b-button
                >

                <b-button
                size="sm"
                :variant="
                data.item.is_active === '0' || data.item.is_active === false
                  ? 'success'
                  : 'danger'
                "
              @click="success(data.item.person_id, data.item.is_active)"
              >{{
                data.item.is_active === "0" || data.item.is_active === false
                  ? $t("Activate")
                  : $t("Deactivate")
              }}</b-button
            >
              </template>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="6"
                sm=""
                class="
                  d-flex
                  align-items-left
                  justify-content-right justify-content-sm-start
                  radius
                  0
                "
              >
                <b-form-select
                  v-model="selected"
                  :options="options"
                  @change="changeFiltering"
                  class="select-config"
                >
                </b-form-select>
              </b-col>
              <small class="mt-1" v-if="$i18n.locale === 'tr'"
                >içerisindeki {{ totalUsersConfirm }} kayıttan
                {{ currentPageConfirm * 1 }}-{{
                  currentPageConfirm * perPage
                }}
                arası ({{ currentPageConfirm }}.sayfa) gösteriliyor
              </small>
              <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPageConfirm*1}}-{{currentPageConfirm*perPage}} (page {{currentPageConfirm}}) of {{totalUsersConfirm}} records</small>

              <!-- Pagination -->
              <b-col
                cols="6"
                sm=""
                class="
                  d-flex
                  align-items-right
                  justify-content-center justify-content-sm-end
                  radius
                  0
                "
              >
                <b-pagination
                  v-model="currentPageConfirm"
                  :total-rows="totalUsersConfirm"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0 r-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :title="$t('Invited Facility Users')">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-md-0
                "
              >
                <!-- <label>Show</label> -->
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("Show") }}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search...')"
                  />
                  <b-button
                    variant="primary"
                    :to="{ name: 'facility_user_create' }"
                    >
                    <span class="text-nowrap">{{ $t("Create Facility User") }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            hover
            ref="refUserListTable"
            class="text-left position-relative"
            striped
            :per-page="perPage"
            :current-page="currentPageNotConfirm"
            :items="filtered_facility_not_confirm_studio_data"
            responsive
            :fields="tableColumns2"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('No Matching Creates Found')"
            :sort-desc.sync="isSortDirDesc"
          >

            <template #cell(resendinvite)="data">
              <template v-if="isMobile === true">
                    <b-button
                size="sm"
                variant="primary"
                @click="
                  reSendInvite(
                    data.item.company_branch_person_role_id,
                    data.item.person_id
                  )
                "
              >
                {{ $t("Re-Send Invite") }}
              </b-button>
              </template>



              <template v-if="isMobile === false">
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="reSendInvite(data.item.company_branch_person_role_id, data.item.person_id)"
                  >
                    {{$t('Re-Send Invite')}}
                </b-button>
              </template>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="6"
                sm=""
                class="
                  d-flex
                  align-items-left
                  justify-content-right justify-content-sm-start
                  radius
                  0
                "
              >
              </b-col>
              <small class="mt-1" v-if="$i18n.locale === 'tr'"
                >içerisindeki {{ totalUsersNotConfirm }} kayıttan
                {{ currentPageNotConfirm * 1 }}-{{
                  currentPageNotConfirm * perPage
                }}
                arası ({{ currentPageNotConfirm }}.sayfa) gösteriliyor
              </small>
              <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPageNotConfirm*1}}-{{currentPageNotConfirm*perPage}} (page {{currentPageNotConfirm}}) of {{totalUsersNotConfirm}} records</small>
              <!-- Pagination -->
              <b-col
                cols="6"
                sm=""
                class="
                  d-flex
                  align-items-right
                  justify-content-center justify-content-sm-end
                  radius
                  0
                "
              >
                <b-pagination
                  v-model="currentPageNotConfirm"
                  :total-rows="totalUsersNotConfirm"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0 r-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>

    </b-card>
  </div>
</template>


<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BTabs,
  BTab
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    BTabs,
    BTab
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,

      filterOn: [],

      locale: this.$i18n.locale,

      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "full_name", label: this.$t("FULL NAME") },
        { key: "email", label: this.$t("E-mail") },
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],

      //tabten sonra eklendi
      searchQuery: "",
      totalUsersConfirm: null,
      totalUsersNotConfirm: null,
      currentPageConfirm: null,
      currentPageNotConfirm: null,

      facilityConfirmBaseData: [],
      facilityNotConfirmBaseData: [],
      facilityConfirmData: [],
      facilityNotConfirmData: [],

      tableColumns2: [
        { key: "full_name", label: this.$t("FULL NAME"), sortable: true },
        { key: "email", label: this.$t("Facility User Email"), sortable: true },
        { key: "invite_created_date", label: this.$t('CREATED DATE'), sortable: true},
        {
          key: "resendinvite",
          label: this.$t("RE-SEND INVITE"),
          sortable: true,
        },
      ],
      //....................

      personData: [],

      selected: "tüm",
      options: [
        { text: this.$t("All Facility Users"), value: "tüm" },
        { text: this.$t("Active Facility Users"), value: "aktif" },
        { text: this.$t("Deactive Facility Users"), value: "deaktif" },
      ],
      companyBranchId: "",
      distributor_person_list: [],
      company_branch_persons_data: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.personData
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    //onaysız facility kullanıcılarının arama sonuçları ile hesaplanması
    filtered_facility_not_confirm_studio_data(){
      let data = [];
      this.facilityNotConfirmData.map((x) => {
        x.fullName = x.name + " " + x.last_name;
        if (x.fullName.toUpperCase().includes(this.searchQuery.toUpperCase())) { //aramayı full name baz alarak yap
          data.push(x);
          this.totalusersNotConfirm = data.length;
          this.currentPageNotConfirm = 1;
        }
      });
      return data;
    },
    //onaylı facility kullanıcılarının arama sonuçları ile hesaplanması
    filtered_facility_confirm_studio_data(){
      let data = [];
      this.facilityConfirmData.map((x) => {
        x.fullName = x.person_name + " " + x.person_last_name;
        if (x.fullName.toUpperCase().includes(this.searchQuery.toUpperCase())) { //aramayı full name baz alarak yap
          data.push(x);
          this.totalUsersConfirm = data.length;
          this.currentPageConfirm = 1;
        }
      });
      return data;
    }
  },
  watch: {
    personData(val) {
      this.totalRows = val.length;
    },
    instructorConfirmData(val) {
      this.totalUsersConfirm = val.length;
    },
    instructorNotConfirmData(val) {
      this.totalUsersNotConfirm = val.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.personData.length;
  },

  methods: {
    //müşterilerşn aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
      if (arg === "tüm") {
        this.facilityConfirmData = this.facilityConfirmBaseData;
      } else if (arg === "aktif") {
        //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
        let data = [];
        this.facilityConfirmBaseData.map((x) => {
          if ((x.is_active === "1" || x.is_active === true) && ( x.is_confirm === true || x.is_confirm === '1')) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.facilityConfirmData = data;
      } else if (arg === "deaktif") {
        //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
        let data = [];
        this.facilityConfirmBaseData.map((x) => {
          if ((x.is_active === "0" || x.is_active === false) && (x.is_confirm === true || x.is_confirm === '1')) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.facilityConfirmData = data;
      } else {
        //null gelebilir..
        this.facilityConfirmData = this.facilityConfirmBaseData;
      }
    },
    success(val, is_active) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change Facility User Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (is_active === "0" || is_active === false) {
            this.$database.DistributorService.person_deactive(val, true).then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Action failed!");
                } else {
                  this.$functions.Messages.success("Facility user status changed");
                  this.personBaseData = [];
                  this.personData = [];
                  this.getFacilityUserData();
                }
              }
            );
          }
          if (is_active === "1" || is_active === true) {
            this.$database.DistributorService.person_deactive(val, false).then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Action failed!");
                } else {
                  this.$functions.Messages.success("Facility user status changed");
                  this.personBaseData = [];
                  this.personData = [];
                  this.getFacilityUserData();
                }
              }
            );
          }
        }
      });
    },

    getPersonData() {
      this.$database.StudioService.get_person_list(
        this.companyBranchId,
        this.emailValue
      ).then((res) => {
        if (!res.is_success) return;
        if (!res.result) return;
        else {
          this.personBaseData = res.result.map((el) => {
            const full_name = el.name + " " + el.last_name;
            return {
              ...el,
              full_name: full_name,
            };
          });
          this.personData = res.result.map((el) => {
            const full_name = el.name + " " + el.last_name;
            return {
              ...el,
              full_name: full_name,
            };
          });
        }
      });
    },
    getCustomerList() {
      // this.$database.StudioService.get_studio_customer_list(
      //   '5e4ca644-d748-451b-a08e-a5bc9d8c862c'
      // ).then(res =>  {
      //   if(!res.is_success) return;
      //   if(!res.result) return;
      //   else {
      //     console.log(res);
      //     //base data
      //     this.personBaseData = res.result.map(el => {
      //       const full_name = el.name + " " + el.last_name
      //       return {
      //         ...el,
      //         full_name: full_name
      //       }
      //     })
      //     //normal data
      //       this.personData = res.result.map(el => {
      //       const full_name = el.name + " " + el.last_name
      //       return{
      //         ...el,
      //         full_name:full_name
      //       }
      //     });
      //   }
      // })
      this.$database.FacilityService.get_facility_person_list()
        .then(async (res) => {
            console.log("Liste burada")

            console.log(res.result)


          if (res.is_success === true || res.is_success === 1) {
            this.distributor_person_list = await res.result;
          }
        })
        .then((res) => {
          this.$database.CustomerService.get_person_and_company_branch_person_data(
            this.companyBranchId
          )
            .then(async (res) => {
              this.company_branch_persons_data = await res.result;
              // console.log(this.distributor_person_list);
              // console.log(this.company_branch_persons_data);
            })
            .then((res) => {
              this.company_branch_persons_data.map((el) => {
                this.distributor_person_list.map((x) => {
                  //person id leri eşleştir distributorData içerisine istedigim verileri pushla
                  if (
                    el.company_branch_person_id === x.company_branch_person_id
                  ) {
                    let full_name = el.name + " " + el.last_name;
                    this.personData.push({
                      is_confirm: x.is_confirm,
                      menu_role_group_id: x.menu_role_group_id,
                      company_branch_person_role_id:
                      x.company_branch_person_role_id,
                      full_name,
                      ...el,
                    });
                  }
                });
              });
            })
            .then((res) => {
              this.personBaseData = this.personData;
            });
        });
    },

     dateFormat: (val) => {
      // console.log(typeof val);
      let sonucDeger;
      if (typeof val !== "undefined") {
        let date = val.split("T")[0].split("-").reverse().join(".");
        let hour = val.split("T")[1].split("+")[0];
        let hourPart = hour.split(":");
        hour =
          hourPart[0] + ":" + hourPart[1] + ":" + hourPart[2].split(".")[0];
        sonucDeger = date + " - " + hour;
      } else {
        sonucDeger = "Bilgi mevcut değil";
      }
      return sonucDeger;
    },


    getFacilityUserData(){
      this.$database.FacilityService.get_facility_user_data(
        this.$route.params.studioID
      ).then(res => {

          console.log(res.result)


        if(res.is_success === true || res.is_success === 1){
          res.result.map(el => {
            if(el.is_confirm === true || el.is_confirm === 1){
              this.facilityConfirmBaseData.push({
                ...el,
                full_name: el.person_name + ' ' + el.person_last_name
              })
            }else {
              this.facilityNotConfirmBaseData.push({
                ...el,
                full_name: el.person_name + ' ' + el.person_last_name,
                invite_created_date: this.dateFormat(el.created_date),
                invite_resend_invite_date: this.dateFormat(
                  el.resend_invite_date
                ),
              })
            }
          })

          let key = "person_id";

          //tekrar eden kayıtların tekilleştirilmesi - onaysız kullanıcılar
          let arrayUniqueByKey = [
            ...new Map(
              this.facilityNotConfirmBaseData.map((item) => [item[key], item])
            ).values(),
          ];
          this.facilityNotConfirmBaseData = [];
          arrayUniqueByKey.map((el) => {
            this.facilityNotConfirmBaseData.push(el);
          });
          this.facilityNotConfirmData = this.facilityNotConfirmBaseData;


          //tekrar eden kayıtların tekilleştirilmesi - onaylı kullanıcılar
          let arrayUniqueByKey2 = [
            ...new Map(
              this.facilityConfirmBaseData.map((item) => [item[key], item])
            ).values(),
          ];
          this.facilityConfirmBaseData = [];
          arrayUniqueByKey2.map((el) => {
            this.facilityConfirmBaseData.push(el);
          });
          this.facilityConfirmData = this.facilityConfirmBaseData;
        }
      })
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reSendInvite(company_branch_person_role_id, person_id) {
      this.$database.AccountService.re_send_invite({
        CompanyBranchPersonRoleId: company_branch_person_role_id,
        PersonId: person_id,
      }).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          this.$functions.Messages.success("Invitation Sent");
        } else {
          this.$functions.Messages.error("Action failed!");
        }
      });
    },
  },
  created() {
    this.companyBranchId = this.$route.params.studioID;
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
  //  this.getCustomerList();
    this.getFacilityUserData();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioUserListScoped.scss" scoped></style>
